import React from 'react'
import Banner from '../components/Banner';
import Layout from '../layout/Layout';
import MainQuote from '../components/Quote';
import Seo from '../layout/Seo';

const remodeling = ({ pageContext }) => {

    const { locale } = pageContext;
    function Child({locale}){
        return(<>
        <Seo 
                title="Remodelaciones en Bogotá" 
                description="Remodelación de casa, remodelación de oficinas, reformas, adecuaciones, acabados, pintura en interiores y exteriores."
                pathname="https:localhost"
            />
            <Banner title={locale.remodeling.banner.title} subtitle={locale.remodeling.banner.subtitle} />
            <MainQuote title={locale.remodeling.mainQuote} locale={locale} />
        </>)
    }
    return (
        <Layout locale={locale} background="bg-image-remodeling">
             {props => <Child {...props} />}
        </Layout>
    )
}

export default remodeling
 
